import { useMemo } from 'react'

import { matchFilterNumber, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { AdAccountLinkRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['adAccountLinks']

type AdAccountLinkFilters = {
  linkableId?: number,
  linkableType?: string,
}

type UseAdAccountLinksOptions = {
  filters?: AdAccountLinkFilters,
  performHttpRequests?: boolean,
  requestOptions?: AdAccountLinkRequestOptions,
}

function useAdAccountLinks(options: UseAdAccountLinksOptions) {
  const { filters = {}, requestOptions } = options
  const {
    linkableId: filterLinkableId,
    linkableType: filterLinkableType,
  } = filters

  const {
    updatedEntities: { adAccountLinks: adAccountLinksUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { adAccounts, adAccountLinks } = useSelector(reduxState => reduxState.entities)

  const filteredAdAccountLinks = useMemo(() => {
    const filtered = Object.values(adAccountLinks).filter((adAccountLink) => {
      const { linkableId, linkableType } = adAccountLink

      const matchLinkableId = filterLinkableId ? matchFilterNumber(linkableId, filterLinkableId) : false
      const matchLinkableType = matchFilterString(linkableType, filterLinkableType)

      return matchLinkableId && matchLinkableType
    })

    return sortArrayBy(filtered, 'asc', ({ adAccountId }) => adAccounts[adAccountId]?.name)
  }, [adAccountLinksUpdatedAt, JSON.stringify(filters)])

  const filteredAdAccountLinksCount = filteredAdAccountLinks.length
  const hasFilteredAdAccountLinks = !!filteredAdAccountLinksCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredAdAccountLinksCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingAdAccountLinks } = useReduxAction(
    'adAccountLinks',
    'loadAdAccountLinks',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredAdAccountLinks,
    filteredAdAccountLinksCount,
    hasFilteredAdAccountLinks,
    loading: loadingAdAccountLinks,
  }
}

export default useAdAccountLinks
